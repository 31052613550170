@font-face {
}

html, body, #root {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	overflow: hidden;

	background-color: #111827;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.clickable {
	cursor: pointer;
}

.noclick {
	cursor: not-allowed;
}