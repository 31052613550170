.sharing {
	position: absolute;
	width: 100%;
	height: 100px;

	bottom: 0;

	background-color: #111827;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.sharing-button {

}

.sharing-shareLine {
	
}